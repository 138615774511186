import "./App.css";

// import { ArrowBackRounded } from "@mui/icons-material";
// import { Button, Grid } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { v4 } from "uuid";
import { Chat } from "./app/chat/Chat";
import { Avatar } from "./app/components/3dAvatar/Avatar";
// import { CircularRpmAvatar } from "./app/components/CircularRpmAvatar";
import { Layout } from "./app/components/Layout";
import { ChatWrapper, MainWrapper, SimulatorHeader } from "./app/components/Simulator";
import { ConfigView } from "./app/configuration/ConfigView";
import { get as getConfiguration, save as saveConfiguration } from "./app/helpers/configuration";
import { Player } from "./app/sound/Player";
import {
  AdditionalPhonemeInfo,
  Character,
  CHAT_HISTORY_TYPE,
  CHAT_VIEW,
  ChatHistoryItem,
  Configuration,
  EmotionEvent,
  EmotionsMap,
} from "./app/types";
import { config } from "./config";
import * as defaults from "./defaults";
import { ref } from "firebase/database";
import { db, writeDataToFirebase } from "./firebase/firebase";
import { chatObjectType } from "./app/chat/Chat";

interface CurrentContext {
  character?: Character;
  chatting: boolean;
  connection?: WebSocket;
  playerName?: string;
}

const sound = new Audio();
const player = new Player();

function App() {
  const formMethods = useForm<Configuration>();

  const [open, setOpen] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [connection, setConnection] = useState<WebSocket>();
  const [character, setCharacter] = useState<Character>();
  const [chatHistory, setChatHistory] = useState<ChatHistoryItem[]>([]);
  const [chatting, setChatting] = useState(true);
  const [chatView, setChatView] = useState(CHAT_VIEW.TEXT);
  const [playerName, setPlayerName] = useState("");
  const [phonemes, setPhonemes] = useState<AdditionalPhonemeInfo[]>([]);
  const [avatar, setAvatar] = useState("");
  const [emotions, setEmotions] = useState<EmotionsMap>({});
  const [emotionEvent, setEmotionEvent] = useState<EmotionEvent>();
  const [startChatting, setStartChatting] = useState(false);

  // state for fetching teh chat history formatted from the chat component
  const [formattedChatHistory, setFormattedChatHistory] = useState<chatObjectType[]>([]);

  // FIREBASE: timestamp for the 30 min session so that the entire session is saved in the same cluster
  const [dateTime, setDateTime] = useState<number>();

  const stateRef = useRef<CurrentContext>();
  stateRef.current = {
    character,
    chatting,
    connection,
    playerName,
  };

  const onOpen = useCallback(() => {
    console.log("Open!");
    // Disable loading from here
    setOpen(true);
  }, []);

  const onDisconnect = useCallback(() => {
    console.log("Disconnect!");
    setOpen(true);
  }, []);

  const onMessage = useCallback((message: MessageEvent) => {
    const packet = JSON.parse(message.data);

    let chatItem: ChatHistoryItem | undefined = undefined;

    if (packet?.type === "AUDIO") {
      player.addToQueue({
        audio: packet.audio,
        onPlay: (packet) => {
          setPhonemes(packet.additionalPhonemeInfo);
        },
      });
    } else if (packet?.type === "TEXT") {
      const { character, playerName } = stateRef.current || {};

      chatItem = {
        id: packet.packetId?.utteranceId,
        type: CHAT_HISTORY_TYPE.ACTOR,
        date: new Date(packet.date!),
        source: packet.routing?.source,
        text: packet.text.text,
        interactionId: packet.packetId?.interactionId,
        isRecognizing: !packet.text.final,
        author: packet.routing!.source!.isCharacter ? character?.displayName : playerName,
      };
    } else if (packet?.control?.type === "INTERACTION_END") {
      chatItem = {
        id: packet.packetId?.utteranceId,
        type: CHAT_HISTORY_TYPE.INTERACTION_END,
        date: new Date(packet.date!),
        source: packet.routing?.source,
        interactionId: packet.packetId?.interactionId,
      };
    } else if (packet?.emotions) {
      setEmotionEvent(packet.emotions);
      setEmotions((currentState) => ({
        ...currentState,
        [packet.packetId.interactionId]: packet.emotions,
      }));
    }

    if (chatItem) {
      // console.log(chatHistory);
      setChatHistory((currentState) => {
        let newState = undefined;
        let currentHistoryIndex = currentState.findIndex((item) => {
          return item.id === chatItem?.id;
        });

        if (currentHistoryIndex >= 0 && chatItem) {
          newState = [...currentState];
          newState[currentHistoryIndex] = chatItem;
        } else {
          newState = [...currentState, chatItem!];
        }
        return newState;
      });
    }
  }, []);

  const [cn, setCn] = useState(""); //cn charname for the firebase instance
  const [pn, setPn] = useState(""); //pn player name for the firebase instance
  const [ppn, setPpn] = useState(""); //ppn phone number for the firebase instance
  const [uuid, setUuid] = useState(""); //uuid is the unique id for the firebase instance
  const [email, setEmail] = useState(""); //email for the firebase instance

  const openConnection = useCallback(async () => {
    // TODO: Change the hardcoded values here

    let params = await new URLSearchParams(window.location.search);
    let charName = params.get("character");
    let playName = params.get("name");
    let phone = params.get("phone");
    let uuid = params.get("uuid");
    let email = params.get("email");
    // console.log(phone);

    // FIREBASE: timestamp at the start of session
    setDateTime(Date.now());

    if (charName === null) {
      charName = "alphonse";
    }

    if (playName === null) {
      playName = "buddy";
    }

    if (phone === null) {
      phone = "none";
    }
    if (uuid === null) {
      uuid = "none";
    }
    if (email === null) {
      email = "none";
    }

    charName = charName.toLowerCase();
    setCn(charName);
    setPn(playName);
    setPpn(phone);
    setUuid(uuid);

    let vals = {
      character: {
        name: `workspaces/digital_friends/characters/${charName}`,
      },
      chatView: CHAT_VIEW.AVATAR,
      player: { name: playName },
      scene: {
        name: `workspaces/digital_friends/scenes/${charName}`,
      },
    };

    const key = v4();

    const { character, chatView, player, scene } = vals; //formMethods.getValues();

    // setChatting(true);
    setChatView(chatView!);
    setPlayerName(player?.name!);

    const response = await fetch(`${config.LOAD_URL}?key=${key}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        scene: scene?.name,
        player: player?.name,
        character: character?.name,
        chatView: chatView,
        phone: phone,
        charName: charName,
        uuid: uuid,
        email: email,
      }),
    });
    const data = await response.json();

    if (!response.ok) {
      return console.log(response.statusText, ": ", data.errors);
    }

    if (data.character) {
      const assets = data.character.assets;
      const rpmImageUri = assets?.rpmImageUriPortrait;
      const avatarImg = assets?.avatarImg;

      setCharacter(data.character as Character);
      setAvatar(avatarImg || rpmImageUri || "");
    }

    const ws = new WebSocket(`${config.SESSION_URL}?key=${key}`);

    setConnection(ws);

    ws.addEventListener("open", onOpen);
    ws.addEventListener("message", onMessage);
    ws.addEventListener("disconnect", onDisconnect);
  }, [formMethods, onDisconnect, onMessage, onOpen]);

  // function to write the chat data onto firebase

  useEffect(() => {
    // if there's a phone number otherwhise there's no user to save the data for so no need to use firebase
    if (uuid !== "none") {
      const reference = ref(db, `digitalFriendsMessages/${uuid}/${cn}/${dateTime}`);

      try {
        if (formattedChatHistory && formattedChatHistory.length > 5) {
          writeDataToFirebase(ppn, pn, formattedChatHistory, reference, dateTime, uuid, email); //phone player chathist, database ref, datetime ref, uuid
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, [formattedChatHistory]);

  const stopChatting = useCallback(async () => {
    // Disable flags
    setChatting(false);
    setOpen(false);

    // Stop audio playing
    player.stop();

    // Clear collections
    setChatHistory([]);

    // Close connection and clear connection data
    connection?.close();
    connection?.removeEventListener("open", onOpen);
    connection?.removeEventListener("message", onMessage);
    connection?.removeEventListener("disconnect", onDisconnect);

    setConnection(undefined);
    setCharacter(undefined);
  }, [connection, onDisconnect, onMessage, onOpen]);

  const resetForm = useCallback(() => {
    formMethods.reset({
      ...defaults.configuration,
    });
    saveConfiguration(formMethods.getValues());
  }, [formMethods]);

  useEffect(() => {
    const configuration = getConfiguration();

    formMethods.reset({
      ...(configuration ? (JSON.parse(configuration) as Configuration) : defaults.configuration),
    });

    setInitialized(true);
  }, [formMethods]);

  useEffect(() => {
    player.preparePlayer({ audio: sound });
    openConnection();
  }, []);

  const content = chatting ? (
    <>
      {open && character ? (
        <div className="overlayContainer">
          <div className="underlay">
            <MainWrapper>
              <ChatWrapper>
                <Avatar
                  emotionEvent={emotionEvent}
                  phonemes={phonemes}
                  visible={chatView === CHAT_VIEW.AVATAR}
                  url={config.RPM_AVATAR || character.assets?.rpmModelUri || defaults.DEFAULT_RPM_AVATAR}
                />
                <Chat
                  chatView={chatView}
                  chatHistory={chatHistory}
                  connection={connection!}
                  emotions={emotions}
                  onStopChatting={stopChatting}
                  playerName={playerName}
                  startChatting={startChatting}
                  setFormattedChatHistory={setFormattedChatHistory}
                />
              </ChatWrapper>
            </MainWrapper>
          </div>

          {!startChatting && (
            <MainWrapper className="overlay">
              <a className="button" onClick={() => setStartChatting(true)}>
                START
              </a>
            </MainWrapper>
          )}
        </div>
      ) : (
        <div className="loadingContainer">
          <img className="loadingGif" src="/loading.gif" alt="Loading..." />
        </div>
      )}
    </>
  ) : (
    <ConfigView canStart={formMethods.formState.isValid} onStart={openConnection} onResetForm={resetForm} />
  );

  return (
    <FormProvider {...formMethods}>
      <Layout>{initialized ? content : ""}</Layout>
    </FormProvider>
  );
}

export default App;
