import { CopyAll, Mic, Send } from "@mui/icons-material";
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import { useCallback, useState, useEffect } from "react";
import MicIcon from "@mui/icons-material/Mic";

import { CHAT_HISTORY_TYPE, CHAT_VIEW, ChatHistoryItem, EmotionsMap } from "../types";
import { ActionsStyled, RecordIcon } from "./Chat.styled";
import { CopyConfirmedDialog } from "./CopyConfirmedDialog";
import { History } from "./History";

interface ChatProps {
  chatView: CHAT_VIEW;
  chatHistory: ChatHistoryItem[];
  connection: WebSocket;
  emotions: EmotionsMap;
  onStopChatting: () => void;
  playerName: string;
  startChatting: boolean;
  setFormattedChatHistory: React.Dispatch<React.SetStateAction<chatObjectType[]>>;
}

export interface chatObjectType {
  talker: String | undefined;
  phrase: String | undefined;
}

let interval: NodeJS.Timeout;
let stream: MediaStream;
let audioCtx: AudioContext;

export function Chat(props: ChatProps) {
  const { chatHistory, connection, startChatting, setFormattedChatHistory } = props;

  const [text, setText] = useState("Hi");
  const [copyDestination, setCopyDestination] = useState("");
  const [copyConfirmOpen, setCopyConfirmOpen] = useState(false);
  const [isRecording, setIsRecording] = useState(false);

  const handleTextChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  }, []);

  const formatTranscript = useCallback((messages: ChatHistoryItem[]) => {
    let transcript = "";
    let previousDialog: chatObjectType[] = [];
    let characterLastSpeaking = false; // Used to combine all Character text chunks

    messages.forEach((item) => {
      switch (item.type) {
        case CHAT_HISTORY_TYPE.ACTOR:
          const isCharacter = item.source.isCharacter;
          let chatObject: chatObjectType = {
            talker: item.author,
            phrase: item.text,
          };
          previousDialog.push(chatObject);
          chatObject;

          transcript += characterLastSpeaking && isCharacter ? item.text : `\n${item.author}: ${item.text}`;
          characterLastSpeaking = isCharacter;
          break;
      }
    });

    setFormattedChatHistory(previousDialog);

    // console.log(previousDialog);
    // return transcript;
  }, []);

  const getTranscript = useCallback(
    (messages: ChatHistoryItem[], startId?: string, endId?: string) => {
      if (!messages.length) {
        return "";
      }

      // get full array by default
      let startIndex: number = 0;
      let endIndex: number = messages.length - 1;

      if (startId || endId) {
        // find start/end indexes of the slice if ids are specified
        messages.forEach((item, index) => {
          if (item.id === startId) {
            startIndex = index;
          }

          if (item.id === endId) {
            endIndex = index;
          }
        });
      }

      if (endIndex < startIndex) {
        const tmp = startIndex;
        startIndex = endIndex;
        endIndex = tmp;
      }

      // generate eventual transcript
      return formatTranscript(messages.slice(startIndex, endIndex + 1));
    },
    [formatTranscript]
  );

  const stopRecording = useCallback(() => {
    setIsRecording(false);
    clearInterval(interval);
    connection.send(JSON.stringify({ type: "audioSessionEnd" }));
  }, [connection]);

  function arrayBufferToBase64(buffer: ArrayBuffer) {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const length = bytes.byteLength;
    for (let i = 0; i < length; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  function mergeBuffers(channelBuffer: Float32Array[], recordingLength: number) {
    const result = new Float32Array(recordingLength);
    let offset = 0;

    for (const element of channelBuffer) {
      result.set(element, offset);
      offset += element.length;
    }

    return Array.prototype.slice.call(result);
  }

  const startRecording = useCallback(async () => {
    try {
      setIsRecording(true);

      stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          sampleRate: 16000,
          echoCancellation: { ideal: true },
          // suppressLocalAudioPlayback: { ideal: true },
        },
        video: false,
      });
      audioCtx = new AudioContext({
        sampleRate: 16000,
      });
      const source = audioCtx.createMediaStreamSource(stream);
      const scriptNode = audioCtx.createScriptProcessor(2048, 1, 1);
      let leftChannel: Float32Array[] = [];
      let recordingLength = 0;

      scriptNode.onaudioprocess = (audioProcessingEvent) => {
        const samples = audioProcessingEvent.inputBuffer.getChannelData(0);
        leftChannel.push(new Float32Array(samples));
        recordingLength += 2048;
      };

      source.connect(scriptNode);
      scriptNode.connect(audioCtx.destination);

      interval = setInterval(() => {
        const PCM16iSamples = Int16Array.from(
          mergeBuffers(leftChannel, recordingLength),
          (k) => 32767 * Math.min(1, k)
        );

        connection.send(
          JSON.stringify({
            type: "audio",
            audio: arrayBufferToBase64(PCM16iSamples.buffer),
          })
        );
        //clear buffer
        leftChannel = [];
        recordingLength = 0;
      }, 200);
    } catch (e) {
      console.log("ERR" + e);
    }
  }, [connection]);

  const handleSend = useCallback(() => {
    if (text) {
      connection.send(JSON.stringify({ type: "text", text }));

      setText("");
    }
  }, [connection, text]);

  // waiting for the chat to start for auto hi
  useEffect(() => {
    if (startChatting) {
      handleSend();
    }
  }, [startChatting]);

  const handleCopyClick = useCallback(async () => {
    // TODO ADD HERE...
    const history = getTranscript(chatHistory);
  }, [getTranscript, chatHistory]);

  const handleTextKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        handleSend();
        setTimeout(() => {
          // copies all the chat history  TODO: fix the chat -1 issue
          handleCopyClick();
        }, 3000);
      }
    },
    [handleSend]
  );

  const handleSpeakClick = useCallback(async () => {
    if (isRecording) {
      stopRecording();
      // copies all the chat history  TODO: fix the chat -1 issue
      setTimeout(() => {
        handleCopyClick();
      }, 3000);
      return;
    } else {
      startRecording();
      return;
    }
  }, [isRecording, startRecording, stopRecording]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        position: "absolute",
        paddingBottom: "4.5rem",
        // overflow: 'hidden',
        zIndex: 1,
      }}
    >
      {/* <History history={chatHistory} chatView={props.chatView} emotions={props.emotions} /> */}
      <ActionsStyled>
        <TextField
          variant="standard"
          fullWidth
          value={text}
          onChange={handleTextChange}
          onKeyPress={handleTextKeyPress}
          sx={{
            backgroundColor: (theme) => theme.palette.grey[100],
            borderRadius: "3rem",
            padding: "0.7rem",
            paddingLeft: "1.5rem",
            fontFamily: "Nunito",
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleSend}>
                  <Send />
                </IconButton>
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
        />
        <IconButton
          onClick={handleSpeakClick}
          sx={{ height: "3rem", width: "3rem", backgroundColor: "#008000", marginLeft: "8px" }}
        >
          {isRecording ? <RecordIcon /> : <MicIcon style={{ color: "#ffffff" }} />}
        </IconButton>
        {/* <IconButton onClick={handleCopyClick}>
          <CopyAll fontSize="small" />
        </IconButton> */}
      </ActionsStyled>

      {/* <CopyConfirmedDialog
        copyConfirmOpen={copyConfirmOpen}
        copyDestination={copyDestination}
        setCopyConfirmOpen={setCopyConfirmOpen}
      /> */}
    </Box>
  );
}
