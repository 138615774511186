// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, isSupported } from "firebase/analytics";
import { DatabaseReference, getDatabase, onValue, ref, set, push } from "firebase/database";
import { chatObjectType } from "../app/chat/Chat";

const firebaseConfig = {
  apiKey: "AIzaSyDLUXw5JNF5cLUECQaQGw34iSQGZW-bx70",
  authDomain: "intaface-firebase.firebaseapp.com",
  databaseURL: "https://intaface-firebase-default-rtdb.firebaseio.com",
  projectId: "intaface-firebase",
  storageBucket: "intaface-firebase.appspot.com",
  messagingSenderId: "885997981512",
  appId: "1:885997981512:web:a29eeb7ee0e691f5cbad7d",
  measurementId: "G-8V7DMVVD12",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);

isSupported().then((supported) => {
  if (supported) {
    const analytics = getAnalytics();
    // analytics code here
  } else {
    console.log("Firebase Analytics is not supported in this environment");
  }
});

export const writeDataToFirebase = (
  // TODO try pushing data instead...
  phoneNumber: string,
  character: string,
  chatHist: chatObjectType[],
  dbRef: DatabaseReference,
  dateTime: number | undefined,
  uuid: string,
  email: string
) => {
  // Uncomment if you want to push new data and not overwrite the session data
  // const chatListRef = dbRef;
  // const newchatRef = push(chatListRef);

  set(dbRef, {
    chatHistory: chatHist,
    dateTime,
    character,
    phoneNumber,
    uuid,
    email,
  });
};

export const getDataFromFirebase = (dbRef: DatabaseReference) => {
  onValue(dbRef, (snapshot) => {
    const data = snapshot.val(); //fetch data from firebase
  });
};
