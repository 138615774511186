import { Box, CssBaseline } from '@mui/material';
import { ReactNode } from 'react';

import { Main } from './Main';

interface LayoutProps {
  children?: ReactNode;
}

export function Layout(props: LayoutProps) {
  return (
    <Box>
      
      <Main >
      
        <Box sx={{ maxWidth: '1200px', mx: 'auto', backgroundColor:'#C15EC1' }}>{props.children}</Box>
      </Main>
      <CssBaseline />
    </Box>
  );
}
